@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,400;0,500;1,400&family=Open+Sans:wght@700&display=swap");

button {
  background: none;
  border: none;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;

  &:focus,
  &:active {
    outline: none;
  }
}

html {
  body {
    font-family: "Montserrat Alternates", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(./images/background.jpg);
    background-size: cover;

    main {
      background: black;
      border-radius: 0.0625rem;
      box-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, 0.23),
        0 0.375rem 0.375rem rgba(0, 0, 0, 0.23);
      max-width: 30rem;
      width: 100%;
      min-height: 30rem;
      padding: 1.2rem 2rem;
      box-sizing: border-box;
      border-bottom: 0.3125rem solid #f02475;

      h1,
      p {
        color: white;
      }

      h1 {
        font-family: "Open Sans", sans-serif;
        font-size: 3.7rem;
        line-height: 1.1;
      }

      em,
      a {
        font-weight: 500;
      }

      em {
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 500;
      }

      p {
        font-weight: 400;
      }

      a,
      p {
        font-size: 1.125rem;
      }

      a {
        color: #f02475;
        font-style: italic;
        font-weight: 500;
      }

      .links {
        margin-top: 3rem;
      }

      #mapWrapper {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: opacity linear 6s;
        opacity: 0;

        iframe {
          width: 100%;
          height: 100%;
        }

        &.show {
          display: block;
          opacity: 1;
        }
      }

      #showMapWrapper {
        position: fixed;
        right: 5rem;
        bottom: 5rem;
        padding: 1rem;
        z-index: 10;
        opacity: 0.4;
        img {
          height: 2rem;
          width: 2rem;
          margin-bottom: 0.5rem;
          margin-top: -2.2rem;
          margin-right: -0.25rem;
        }
      }

      #closeMapWrapper {
        position: fixed;
        top: 1rem;
        right: 1rem;
        font-size: 4rem;
        color: #f02475;
      }
    }

    aside {
      position: fixed;
      bottom: 0.0625rem;
      left: 0.0625rem;
      background: rgba(150, 150, 150, 0.3);
      padding: 0.125rem;
      font-size: 0.75rem;
    }
  }
}
